<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
            @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button" v-show="!config.isDetailDisplay">
                <div class="h-b"><el-button type="primary" size="small" @click="del" v-right-code="'Cacheinfomanage:Delete'">删除缓存</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="clearAllCache" v-right-code="'Cacheinfomanage:Delete'">清空所有缓存</el-button> </div>
            </div>
            <p slot="elList">
                <el-table ref="apiListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange"
                    highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col, index, count) in dataSource.ColDefs.BodyFieldParams" :key="index"
                        :prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam, col)"
                        :fixed="index == 0 && (!config || !config.isDetailDisplay)"
                        :min-width="(!config || !config.isDetailDisplay) ? parseInt(col.Width) : 0"
                        v-if="col.Visible">
                        <template slot-scope="scope">
                            <span> {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>
export default {
    mounted() {
        this.reloadPageList();
    },
    data() {
        return {
            multipleSelection: [],
            dataSource: {
                ColDefs: {
                    BodyFieldParams: []
                },
                Result: [],
                TotalCount: 0
            },
            queryParam: {
                PageIndex: 1,
                PageSize: 10
            },
        }
    },
    props: {
        config: {
            isDetailDisplay: false,
        }
    },
    methods: {
        reloadPageList() {
            this.onPageChange(this.queryParam);
        },
        onDataSourceChange(ds) {
            var _this = this;
            _this.dataSource = {
                ColDefs: {
                    BodyFieldParams: []
                },
                Result: [],
                TotalCount: 0
            };
            _this.$nextTick(function () {
                _this.dataSource = ds;
            });
        },
        onPageChange(param) {
            this.queryParam = param;
            this.$ajax.send("omsapi/cacheinfomanage/getlist", "post", param, data => {
                this.dataSource = data;
            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        clearAllCache() {
            var _this = this
            this.$confirm('确定要清空所有缓存吗？（只会清空cache:开头的缓存）').then(_ => {
                _this.$ajax.send("omsapi/cacheinfomanage/clearall", "post", {}, data => {
                    if (data.IsSuccess) {
                        _this.Utils.messageBox("操作成功.", "success");
                        _this.reloadPageList();
                    } else {
                        _this.Utils.messageBox(data.OperationDesc, "error");
                    }
                });
            }).catch(_ => { });
        },
        del() {
            if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                this.Utils.messageBox(`未选数据.`, "error");
                return false;
            }
            var ids = this.Utils.selectionsToArr(this.multipleSelection, "Key");
            var _this = this
            this.$confirm('确定要删除吗？').then(_ => {
                _this.$ajax.send("omsapi/cacheinfomanage/delete", "post", ids, data => {
                    if (data.IsSuccess) {
                        _this.Utils.messageBox("删除成功.", "success");
                        _this.reloadPageList();
                    } else {
                        _this.Utils.messageBox(data.OperationDesc, "error");
                    }
                });
            }).catch(_ => { });
        },
    },
}
</script>